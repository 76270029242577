import * as React from "react"
import { graphql } from "gatsby"
import { Global } from "@emotion/core"

import Header from "../../components/header"
import Layout from "../../layouts"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"
import ShowcaseDetails from "./showcase-details"

export default function ShowcaseTemplate({
  data: { wpShowcaseSite: site },
  location,
  pageContext: { hidePrevNext },
}) {
  const isModal =
    location.state && location.state.isModal && window.innerWidth > 750

  return (
    <Layout pathname={location.pathname}>
      <Global
        styles={{
          header: {
            zIndex: "0 !important",
          },
        }}
      />
      {!isModal && <Header />}
      <main>
        <SkipNavTarget />
        <ShowcaseDetails
          isModal={isModal}
          site={site}
          location={location}
          hidePrevNext={hidePrevNext}
        />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ShowcaseDetails($id: String!) {
    wpShowcaseSite(id: { eq: $id }) {
      id
      slug
      title
      categories {
        nodes {
          id
          name
        }
      }
      showcaseSiteFields {
        mainUrl
        builtBy
        builtByUrl
        description
        screenshot {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 750)
              resize {
                src
              }
            }
          }
        }
        sourceUrl
      }
    }
  }
`
